import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts/index';

const NotFoundPage = ({ data }) => {
	return (
		<Layout bodyClass="page">
			<div className="home-hero-wrapper">
				<div className="intro pb-4">
					<div className="container">
						<h1>Sorry, this page can not be found</h1>
						<p>You just found a page that doesn&#39;t exist...</p>
						<a
							className="button button-primary mt-2"
							href={`${data.site.siteMetadata.siteUrl}en/`}
						>
							Go to the homepage
						</a>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default NotFoundPage;

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
